.RichEditor-editor,
.public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root {
  margin: 0 -15px -15px;
  padding: 0 15px;
}
.public-DraftEditorPlaceholder-root {
  color: var(fbui-desktop-text-placeholder);
  position: absolute;
  z-index: 0;
  color: #9197a3;
  max-width: 700px;
}
.public-DraftEditorPlaceholder-inner {
  user-select: none;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}
.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}
.DraftEditorPlaceholder-hidden {
  display: none;
}
