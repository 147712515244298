.smartbanner-description {
  display: none;
}

.smartbanner-android {
  background: #f2f2f2;
  box-shadow: none;
}

.smartbanner-close {
  color: #b1b1b3 !important;
  background: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.smartbanner-info {
  text-shadow: none !important;
  color: #000 !important;
}

.smartbanner-title {
  color: #000 !important;
  font-size: 15px;
}

.smartbanner-button-text {
  background: none !important;
  background: #0c71fd !important;
  border: none !important;
  color: white !important;
  border-radius: 15px !important;
  padding: 5px 16px !important;
  text-shadow: none !important;
}

.smartbanner-button {
  box-shadow: none !important;
  text-transform: none !important;
  font-weight: 700 !important;
}
