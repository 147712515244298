@import './assets/css/loader.css';
html {
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  max-width: 100vw;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 1em;
  max-width: 100vw;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

a {
  font-size: 0.875rem;
  color: black;
}

p,
li {
  /*font-size: .9375em;*/
  /*font-size: .75em;*/
  font-size: 0.875em;
}

ul {
  padding-left: 1.5em;
  line-height: 1.5;
}

p {
  line-height: 1.375;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

svg {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

/* SVG */

.progress-icon,
.progress-icon * {
  fill: #fff;
}

.progress-icon {
  width: 1.1em;
  height: 1.1em;
}

.progress-icon.big {
  width: 1.4em;
  height: 1.4em;
}

.candidate-action-button-icon {
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.45em;
  transform: translateY(1px);
}

.icon__bubble {
  width: 0.9em;
  height: 0.9em;
  fill: #d8d8d8;
  margin-right: 0.45em;
}

/* Extensions */

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0;
}

.public-DraftEditor-content > div > div {
  margin: 0 0 10px 0 !important;
}

.public-DraftEditor-content > div > h1,
h2,
h3 {
  margin: 0.1px 0 !important;
}

.public-DraftEditor-content > div > ul {
  margin: 5px !important;
}

.public-DraftEditor-content > div > ul > li {
  margin: 0 !important;
}

/* Userflow */
.userflowjs-banner--embed-mode-BODY_FIRST {
  position: absolute;
}
/* General form styling */

.form-label {
  display: block;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #777;
}

.competence-label {
  margin-bottom: 1.1rem;
  font-size: 0.7rem;
}
/* AI suggest button */
.form-label-ai {
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
}

.form-input {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0.75em 0.8em;
  margin: 0.5em 0;
  font-size: 1.1em;
  width: 100%;
}

@import './assets/css/draft.css';
