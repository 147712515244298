@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600;700&display=swap);
html {
  font-size: 62.5%;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
  color: #26253e;
  background: #f7f8f8;
  display: flex;
  align-items: stretch;
  padding: 0;
  margin: 0;
}

svg {
  fill: currentColor;
}

#app {
  width: 100%;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

a,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.client-content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  padding: 0 2em 2em 2em;
}

.client-main {
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  clear: both;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.row {
  margin: 30px 0 0 0;
  display: flex;
}

@media (max-width: 1600px) {
  .wrapper {
    height: 500px;
  }
}
.topnav {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topnav-links {
  list-style: none;
  display: flex;
  padding: 0;
}

.topnav-links li {
  margin: 30px 30px 0px 30px;
}

.fontawesome {
  font-size: 1.6em;
  transition: all 0.2s ease-in;
}

.fontawesome:hover {
  color: #39837a40;
  transition: all 0.2s ease-in;
}

li .active-icone {
  color: #39837a40;
}

.active-link {
  border-bottom: 3px solid #39837a40;
  color: #39837a40;
}

.number-widget {
  border-radius: 3px;
  text-align: center;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  height: 175px;
  padding: 0 0 10px;
}

.number-widget > div {
  padding: 20px 0;
}

.number-balls {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation-duration: 2s;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.5s ease-in;
}

.greenballs {
  background: #39837a40;
  animation-name: animateGreen;
  border: 1px solid #39837a;
}

.greenballs:hover {
  background: #39837a;
  transition: background 0.5s ease-in;
}

@keyframes animateGreen {
  0% {
    background: white;
    margin-top: -500px;
  }
  25% {
    background: white;
    margin-top: 25px;
  }
  50% {
    background: #45bcb7;
    margin-top: 0;
  }
  100% {
    background: #39837a40;
  }
}
.grayball {
  background-color: #fff;
  animation-name: animateGray;
  border: 1px solid #39837a;
}

.grayball:hover {
  background: #f6f6f6;
  transition: background 0.5s ease-in;
}

@keyframes animateGray {
  0% {
    background: white;
    margin-top: -500px;
  }
  25% {
    background: white;
    margin-top: 25px;
  }
  50% {
    background: #39837a40;
    margin-top: 0;
  }
  100% {
    background: #fff;
  }
}
.react-tabs__tab-list {
  margin: 0;
  padding: 0;
  display: flex;
}
.react-tabs__tab {
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background-color: white;
  border-top: 2px solid #26253e;
  border-right: 2px solid #26253e;
  border-left: 2px solid #26253e;
  margin: 0 2px 0 0;
  border-radius: 5px 5px 0 0;
  transition: all 0.3s;
}
.react-tabs__tab--selected {
  background: #26253e;
  color: white;
  border-radius: 5px 5px 0 0;
}
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
.react-tabs__tab:focus {
  box-shadow: 0 0 5px #0188fe;
  border-color: #0188fe;
  outline: none;
}
.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}
.react-tabs__tab:hover {
  background-color: #26253e;
  color: white;
}
.react-tabs__tab-panel {
  display: none;
}
.react-tabs__tab-panel--selected {
  display: block;
}

.chart {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.graphView-lineChart {
  width: 47%;
  height: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.Leaderboard {
  width: 48%;
  height: auto;
  box-sizing: border-box;
  margin: 30px 0 0 0;
}
.Leaderboard h2 {
  font-size: 22px;
  margin: 10px auto;
}
.Leaderboard th {
  padding: 10px;
  font-size: 18px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  background: #26253e;
  color: white;
}
.Leaderboard td {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.Leaderboard tr:nth-child(even) {
  background-color: #f1f1f3;
}

.leaderboard-view {
  height: auto;
  box-sizing: border-box;
  margin: 9px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.leaderboard-wrapper {
  display: flex;
  justify-content: space-between;
}
.leaderboard-wrapper h2 {
  font-size: 22px;
  margin: 10px auto;
}
.leaderboard-wrapper th {
  padding: 15px 10px;
  font-size: 18px;
  text-decoration: underline;
  background: #26253e;
  color: white;
}
.leaderboard-wrapper td {
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.leaderboard-wrapper tr:nth-child(even) {
  background-color: #f7f8f8;
}

.Leaderboard table {
  width: 100%;
}

.user-panel {
  width: 100%;
}

.team-panel {
  width: 100%;
}

.leaderboard-content {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  min-height: 100px;
  max-height: 250px;
}
.leaderboard-content img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
}

.leaderboard-view-content {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  min-height: 100px;
  max-height: 300px;
  width: 49%;
}
.leaderboard-view-content img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
}

@media (max-width: 1600px) {
  .leaderboard {
    height: 400px;
  }
}
.highscore-title {
  font-weight: bold;
  color: #696969;
  text-align: center;
}

.teamgoals {
  width: 50%;
  height: 400px;
  background: #fff;
  margin: 100px auto;
  padding: 20px;
  text-align: center;
}

.position-graph {
  width: 50%;
  height: 500px;
  text-align: center;
  margin: 30px 0;
}

@media (max-width: 1600px) {
  .position-graph {
    height: 400px;
  }
}
.idv-bar {
  width: 30%;
  margin: 1.66%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.NumberView {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.number-view-box {
  background: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 30%;
  text-align: center;
  margin: 1.66%;
  height: 165px;
}
.number-view-box h3 {
  padding: 5px;
  font-weight: bold;
  text-align: left;
  color: white;
  margin: 0;
}
.number-view-box p {
  padding: 5px;
  border-bottom: 1px solid #c9c9c9;
  text-align: left;
  color: white;
}
.number-view-box div {
  display: flex-root;
  align-items: center;
  justify-content: center;
}
.number-view-box div h1 {
  margin-top: 30px;
}

.number-view-bow-wrapper {
  background-color: #26253e;
}

.top-three {
  display: flex;
  justify-content: center;
}
.top-three div:first-of-type {
  order: 2;
}
.top-three div:last-of-type {
  order: 3;
}
.top-three .user-orbs figure {
  text-align: center;
}
.top-three .user-orbs figure img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transition: all 0.3s ease-in;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.1), 3px 3px 4px 0 rgba(0, 0, 0, 0.1);
  animation-name: animate-highscore;
  animation-duration: 2s;
}
.top-three .user-orbs .position-orb {
  padding-top: 2px;
  width: 25px !important;
  bottom: 45px !important;
  position: relative !important;
  right: -78px !important;
  background-color: green;
  height: 25px !important;
  border-radius: 50%;
  animation-name: animate-highscore;
  animation-duration: 2s;
}
.top-three .user-orbs .position-orb span {
  color: white;
}
.top-three .leader {
  transform: translateY(-12.5px);
}
.top-three .leader .position-orb {
  bottom: 58px !important;
  right: -104px !important;
  background-color: #ffc900;
}
.top-three .leader figure img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}
.top-three .silver .position-orb {
  background-color: #c5c5c5;
}
.top-three .bronze .position-orb {
  background-color: #e2ae5a;
}

@keyframes animate-highscore {
  0% {
    margin-top: -500px;
  }
  25% {
    margin-top: 25px;
  }
  50% {
    margin-top: 0;
  }
}
progress {
  background-color: #f3f3f3;
  border: 0;
  width: 95%;
  height: 18px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

progress::-webkit-progress-bar {
  background-color: #f3f3f3;
}

progress::-webkit-progress-value {
  background-color: #39837a40;
}

/*# sourceMappingURL=styles.css.map */

.smartbanner-description {
  display: none;
}

.smartbanner-android {
  background: #f2f2f2;
  box-shadow: none;
}

.smartbanner-close {
  color: #b1b1b3 !important;
  background: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.smartbanner-info {
  text-shadow: none !important;
  color: #000 !important;
}

.smartbanner-title {
  color: #000 !important;
  font-size: 15px;
}

.smartbanner-button-text {
  background: none !important;
  background: #0c71fd !important;
  border: none !important;
  color: white !important;
  border-radius: 15px !important;
  padding: 5px 16px !important;
  text-shadow: none !important;
}

.smartbanner-button {
  box-shadow: none !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

@keyframes loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loader-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}
.loader {
  position: relative;
  font-size: 0.5em;
  width: 5em;
  height: 5em;
  margin: auto;
}
.loader div {
  left: 2.25em;
  top: 0.125em;
  position: absolute;
  animation: loader linear 1s infinite;
  background: #fff;
  width: 0.375em;
  height: 1.0625em;
  border-radius: 20%;
  transform-origin: 0.1875em 2.40625em;
}
.loader div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.916666666666667s;
}
.loader div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.833333333333333s;
}
.loader div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
}
.loader div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.666666666666667s;
}
.loader div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.583333333333333s;
}
.loader div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
}
.loader div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.416666666666667s;
}
.loader div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.333333333333333s;
}
.loader div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
}
.loader div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.166666666666667s;
}
.loader div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.083333333333333s;
}
.loader div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.RichEditor-editor,
.public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root {
  margin: 0 -15px -15px;
  padding: 0 15px;
}
.public-DraftEditorPlaceholder-root {
  color: var(fbui-desktop-text-placeholder);
  position: absolute;
  z-index: 0;
  color: #9197a3;
  max-width: 700px;
}
.public-DraftEditorPlaceholder-inner {
  -webkit-user-select: none;
          user-select: none;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}
.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}
.DraftEditorPlaceholder-hidden {
  display: none;
}

html {
  font-size: 100%;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  max-width: 100vw;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 1em;
  max-width: 100vw;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

a {
  font-size: 0.875rem;
  color: black;
}

p,
li {
  /*font-size: .9375em;*/
  /*font-size: .75em;*/
  font-size: 0.875em;
}

ul {
  padding-left: 1.5em;
  line-height: 1.5;
}

p {
  line-height: 1.375;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

svg {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

/* SVG */

.progress-icon,
.progress-icon * {
  fill: #fff;
}

.progress-icon {
  width: 1.1em;
  height: 1.1em;
}

.progress-icon.big {
  width: 1.4em;
  height: 1.4em;
}

.candidate-action-button-icon {
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.45em;
  transform: translateY(1px);
}

.icon__bubble {
  width: 0.9em;
  height: 0.9em;
  fill: #d8d8d8;
  margin-right: 0.45em;
}

/* Extensions */

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0;
}

.public-DraftEditor-content > div > div {
  margin: 0 0 10px 0 !important;
}

.public-DraftEditor-content > div > h1,
h2,
h3 {
  margin: 0.1px 0 !important;
}

.public-DraftEditor-content > div > ul {
  margin: 5px !important;
}

.public-DraftEditor-content > div > ul > li {
  margin: 0 !important;
}

/* Userflow */
.userflowjs-banner--embed-mode-BODY_FIRST {
  position: absolute;
}
/* General form styling */

.form-label {
  display: block;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #777;
}

.competence-label {
  margin-bottom: 1.1rem;
  font-size: 0.7rem;
}
/* AI suggest button */
.form-label-ai {
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
}

.form-input {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0.75em 0.8em;
  margin: 0.5em 0;
  font-size: 1.1em;
  width: 100%;
}

